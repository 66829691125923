import { Link } from "gatsby";
import { ImageDataLike } from "gatsby-plugin-image";
import * as React from "react";
import { Box, Button, Card, Flex, Heading, Paragraph } from "theme-ui";
import { DynamicImage } from "../elements/Image";

interface IRegionCard {
  title: string;
  description: string;
  image: ImageDataLike;
}

export function RegionCard({ title, description, image }: IRegionCard) {
  return (
    <Card
      variant='image'
      as={Link}
      to={`/regions/${title.toLowerCase().replaceAll(" ", "-")}`}
      sx={{
        overflow: "hidden",
        maxWidth: 320,
        margin: "0 auto",
        textDecoration: "none",
      }}
    >
      <DynamicImage src={image} alt={title} sx={{ minHeight: 300 }} />
      <Box>
        <Heading
          as='h3'
          variant='h3'
          sx={{ textAlign: "center", marginTop: 2, textDecoration: "none" }}
        >
          {title}
        </Heading>
        <Paragraph variant='paragraphCentered' sx={{ textDecoration: "none" }}>
          {description}
        </Paragraph>
        <Flex sx={{ justifyContent: "center" }}>
          <Button variant='ghost'>Explore Region</Button>
        </Flex>
      </Box>
    </Card>
  );
}
