import { graphql, Link } from "gatsby";
import * as React from "react";
import { Grid } from "theme-ui";
import { FeedbackCard } from "../components/cards/feedback";
import { RegionCard } from "../components/cards/region";
import { Hero } from "../components/landing-page/hero";
import { Layout } from "../components/layout/layout";
import { Section } from "../components/layout/section";
import { CallToAction } from "../components/sections/calltoaction";
import { HowItWorks } from "../components/sections/stepper";
import { Testimonials } from "../components/sections/testimonials";
import { motion } from "framer-motion";
import { FeedbackShowcase } from "../components/sections/FeedbackShowcase";

interface IData {
  data: {
    home: {
      meta: object;
      seo: {
        title: string;
        description: string;
        twitterCard: string;
        image: {
          url: string;
        };
      };
      heroTitle: string;
      heroList: {
        value: object[];
      };
      heroImage: {
        url: string;
      };
      heroBackdrop: {
        url: string;
      };
      heroDescription: string;
      heroButtonLink: string;
      feedbackTypes: {
        map(
          arg0: (type: any) => import("theme-ui/jsx-runtime").JSX.Element
        ): React.ReactNode;
        icon: object;
        title: string;
        description: string;
      };
      feedbackHeadline: string;
      feedbackSubtext: string;
      heroCardAssessmentsTaken: string;
      heroCardSkills: number;
      heroCardTraits: number;
    };
    regions: {
      edges: {
        length: number;
        map(
          arg0: (region: any) => import("theme-ui/jsx-runtime").JSX.Element
        ): React.ReactNode;
        node: {
          regionName: string;
          regionDescription: string;
        };
      };
    };
  };
}

// markup
const IndexPage = ({ data }: IData) => {
  const {
    heroTitle,
    heroList,
    heroImage,
    heroDescription,
    heroBackdrop,
    feedbackTypes,
    feedbackHeadline,
    feedbackSubtext,
    heroCardAssessmentsTaken,
    heroCardSkills,
    heroCardTraits,
    heroButtonLink,
  } = data.home;

  const heroCard = { heroCardAssessmentsTaken, heroCardSkills, heroCardTraits };

  const seo = {
    title: data.home.seo.title,
    description: data.home.seo?.description,
    image: data.home.seo?.image,
    meta: data.home.meta,
  };
  return (
    <>
      <Layout seo={seo}>
        <Hero
          title={heroTitle}
          description={heroDescription}
          list={heroList.value}
          backdrop={heroBackdrop?.url}
          image={heroImage}
          cardData={heroCard}
          link={heroButtonLink}
        />
        <FeedbackShowcase
          title={feedbackHeadline}
          description={feedbackSubtext}
          feedback={feedbackTypes}
        />
        <Section
          title='Explore Opportunities Within Your Region'
          sx={{ backgroundColor: "muted" }}
          id='region'
        >
          {/* Replace this with Carousel when we have more Regions */}
          <Grid
            columns={[
              1,
              data?.regions?.edges.length < 4 ? data?.regions?.edges.length : 4,
            ]}
            sx={{ maxWidth: 700, margin: "28px auto" }}
          >
            {data?.regions?.edges.map((region) => (
              <RegionCard
                key={region.node.regionName}
                title={region.node.regionName}
                description={region.node.regionDescription}
                image={region.node.regionThumbnail}
              />
            ))}
          </Grid>
        </Section>

        <HowItWorks />

        <Testimonials />
        <CallToAction />
      </Layout>
    </>
  );
};

export const query = graphql`
  {
    home: datoCmsLandingPage {
      seo {
        title
        description
        image {
          url
        }
      }
      meta {
        createdAt
        updatedAt
        publishedAt
        firstPublishedAt
        isValid
        status
      }
      heroTitle
      heroList {
        value
      }
      heroImage {
        url
        gatsbyImageData
        mimeType
        alt
      }
      heroBackdrop {
        url
      }
      heroDescription
      heroButtonLink
      feedbackHeadline
      feedbackSubtext
      feedbackTypes {
        title
        description
        icon {
          gatsbyImageData
          mimeType
          url
          alt
        }
      }
      heroCardAssessmentsTaken
      heroCardSkills
      heroCardTraits
    }
    regions: allDatoCmsRegion {
      edges {
        node {
          regionThumbnail {
            url
            gatsbyImageData
            mimeType
            alt
          }
          regionName
          regionDescription
        }
      }
    }
  }
`;

export default IndexPage;
