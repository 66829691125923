import Stepper, {
  Step,
  StepDescription,
  StepTitle,
} from "calcite-react/Stepper";
import CalciteThemeProvider, {
  CalciteTheme,
} from "calcite-react/CalciteThemeProvider";
import { AnimatePresence, motion } from "framer-motion";
import * as React from "react";
import { Box, Heading, Text } from "theme-ui";
import { Section } from "../layout/section";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { DynamicImage } from "../elements/Image";
import { useStaticQuery, graphql } from "gatsby";
import styled, { css } from "styled-components";
import { useMediaQuery } from "../../hooks/useMediaQuery";

gsap.registerPlugin(ScrollTrigger);

export function HowItWorks() {
  const data = useStaticQuery(graphql`
    {
      page: datoCmsLandingPage {
        howItWorksHeadline
        steps {
          title
          content
          image {
            gatsbyImageData
            url
            mimeType
          }
        }
      }
      laptop: datoCmsAsset(id: { eq: "DatoCmsAsset-9582970" }) {
        url
        mimeType
        gatsbyImageData
      }
    }
  `);

  const CustomTheme = {
    ...CalciteTheme,
    palette: {
      ...CalciteTheme.palette,
      blue: "#8d73fe",
    },
  };

  const isLargerThan1024 = useMediaQuery("(min-width: 1175px)");

  /**
   * Setup Stepper
   */
  const [currentStep, setCurrentStep] = React.useState(1);
  /**
   * Implement Element Control
   */
  const sectionRef = React.useRef(null);

  /**
   * Setup Timeline
   */
  React.useEffect(() => {
    if (isLargerThan1024) {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: sectionRef.current,
          pin: true,
          start: "top",
          scrub: 1,
          // snap: {
          //   snapTo: "labels", // snap to the closest label in the timeline
          //   duration: { min: 5 }, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
          //   delay: 0.2, // wait 0.2 seconds from the last scroll event before doing the snapping
          //   ease: "circ.out", // the ease of the snap animation ("power3" by default)
          // },
        },
      });
      data?.page?.steps?.forEach((step: object, idx: number) => {
        function changeStep(index: number) {
          if (index === 0) {
            setCurrentStep(1);
          }
          if (currentStep !== index + 1) {
            setCurrentStep(index + 1);
          }
        }
        if (idx === 0) {
          tl.addLabel("#step-label-" + idx)
            .call(() => changeStep(idx))
            .fromTo(
              "#step-image-" + idx,
              {
                delay: 0.5,
                duration: 1,
                y: 0,
                ease: "circ.out",
              },
              {
                delay: 0.5,
                duration: 1,
                y: -200,
                opacity: 0,
                ease: "circ.out",
              },
              "#step-label-" + idx
            )
            .call(() => changeStep(idx));
        } else {
          // Add NO FADE at last (use idx - 1)
          tl.addLabel("#step-label-" + idx)
            .call(() => changeStep(idx))
            .fromTo(
              "#step-image-" + idx,
              { opacity: 0, y: 200, duration: 1, ease: "circ.out" },
              { opacity: 1, y: 0, ease: "circ.out" },
              "#step-label-" + idx
            )
            .fromTo(
              "#step-image-" + idx,
              {
                delay: 0.5,
                duration: 1,
                ease: "circ.out",
              },
              {
                delay: 0.5,
                duration: 1,
                y: -200,
                opacity: 0,
                ease: "circ.out",
              },
              ">"
            )
            .call(() => changeStep(idx));
        }
      });
    }
  }, [gsap, sectionRef, isLargerThan1024]);

  return (
    <Box
      sx={{
        width: "100%",
        height: isLargerThan1024 ? "100vh" : "auto",
        overflow: "hidden",
      }}
      ref={sectionRef}
      id='howitworks'
    >
      <Box sx={{ position: "relative", height: "100%" }}>
        <Section
          sx={{
            overflow: "hidden",
            height: "100%",
            position: "relative",
            width: "100%",
          }}
        >
          <Heading variant='h2' sx={{ textAlign: "center" }}>
            How the Assessment Works
          </Heading>
          {isLargerThan1024 ? (
            <>
              <Box
                sx={{
                  minHeight: [300, 500],
                  maxWidth: [300, 800],
                  position: "relative",
                  marginTop: "10%",
                }}
              >
                {data?.page?.steps?.map(
                  (step: { image: object; title: string }, idx: string) => {
                    return (
                      <Box
                        key={"step-image-" + idx}
                        id={"step-image-" + idx}
                        sx={{ position: "absolute", top: 0, left: 0 }}
                      >
                        <DynamicImage
                          sx={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                          src={step.image}
                          alt={step.title}
                        />
                      </Box>
                    );
                  }
                )}
              </Box>
              <Box
                sx={{
                  position: ["relative", "relative", "absolute"],
                  right: 0,
                  top: 7,
                  maxWidth: 390,
                }}
              >
                <Box
                  sx={{
                    marginTop: 3,
                    marginBottom: 3,
                    "& > div": {
                      "& > div": {
                        overflow: "visible",
                      },
                    },
                    "div[class^='StyledStepIcon-']": {
                      marginRight: "8px",
                    },
                    "div[class^='StyledAvatar-']": {
                      border: "1.5px solid rgb(170, 170, 170) !important",
                      backgroundColor: "#fff !important",
                      color: "#8d73fe !important",
                      "span[class^='StyledAvatarText-']": {
                        fontFamily: "body",
                        color: "rgb(170, 170, 170) !important",
                        zIndex: 10,
                      },
                    },
                    ".active-step": {
                      "div[class^='StyledAvatar-']": {
                        border: "1.5px solid #8d73fe !important",
                        backgroundColor: "#fff !important",
                        "span[class^='StyledAvatarText-']": {
                          fontFamily: "body",
                          color: "#8d73fe !important",
                          zIndex: 10,
                        },
                      },
                    },
                  }}
                >
                  <CalciteThemeProvider theme={CustomTheme}>
                    <Stepper vertical currentStep={currentStep}>
                      {data?.page?.steps.map(
                        (
                          step: { title: string; content: string },
                          idx: number
                        ) => {
                          return (
                            <Step
                              key={idx}
                              className={`step-${idx + 1} ${
                                currentStep === idx + 1 && "active-step"
                              }`}
                            >
                              <Box
                                as='span'
                                sx={{
                                  overflow: "hidden",
                                  h6: {
                                    color: "rgb(0 0 0 / 60%)",
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                    lineHeight: "22px",
                                    marginBottom: 2,
                                  },
                                  p: {
                                    color: "rgb(0 0 0 / 60%)",
                                    fontSize: "18px",
                                  },
                                }}
                              >
                                <StepTitle>{step.title}</StepTitle>
                                <AnimatePresence>
                                  {currentStep === idx + 1 && (
                                    <motion.div
                                      initial={{
                                        y: 200,
                                        opacity: 0,
                                        height: 0,
                                      }}
                                      animate={{
                                        y: 0,
                                        opacity: 1,
                                        height: "auto",
                                      }}
                                      exit={{
                                        y: -200,
                                        opacity: 0,
                                        height: 0,
                                      }}
                                    >
                                      <StepDescription>
                                        {step.content}
                                      </StepDescription>
                                    </motion.div>
                                  )}
                                </AnimatePresence>
                              </Box>
                            </Step>
                          );
                        }
                      )}
                    </Stepper>
                  </CalciteThemeProvider>
                </Box>
              </Box>
            </>
          ) : (
            <Box>
              {data?.page?.steps?.map(
                (
                  step: { image: object; title: string; content: string },
                  idx: string
                ) => {
                  return (
                    <Box sx={{ margin: "32px auto", textAlign: "center" }}>
                      <Heading
                        as='h6'
                        sx={{ fontWeight: "bold", fontSize: 20 }}
                      >
                        {step.title}
                      </Heading>
                      <Text as='span' sx={{ fontSize: 18 }}>
                        {step.content}
                      </Text>
                      <Box sx={{ margin: "18px auto" }}>
                        <DynamicImage
                          sx={{
                            width: 350,
                            objectFit: "contain",
                          }}
                          src={step.image}
                          alt={step.title}
                        />
                      </Box>
                    </Box>
                  );
                }
              )}
            </Box>
          )}
        </Section>
      </Box>
    </Box>
  );
}
