import * as React from "react";
import {
  Box,
  Button,
  Card,
  Container,
  Flex,
  Grid,
  Heading,
  Image,
  NavLink,
  Paragraph,
  Text,
} from "theme-ui";
import { StructuredText } from "react-datocms";
import { List } from "../elements/List";
import { DynamicImage } from "../elements/Image";
import { LayoutGroup } from "framer-motion";
import { useSlider } from "../../hooks/useSlider";
import { motion } from "framer-motion";
import { useMediaQuery } from "../../hooks/useMediaQuery";

interface IHero {
  title: string;
  description: string;
  list: Document | Node | StructuredText<Record, Record> | null | undefined;
  image: object[];
  backdrop: string;
  cardData: {
    heroCardAssessmentsTaken: string;
    heroCardSkills: number;
    heroCardTraits: number;
  };
  link: string;
}

export function Hero({
  title,
  description,
  list,
  image,
  cardData,
  backdrop,
  link,
}: IHero) {
  const { index } = useSlider(image);
  const isLargerThan551 = useMediaQuery("(min-width: 551px)");
  return (
    <Box sx={{ backgroundColor: "text" }}>
      <Container>
        <Grid columns={[1, 2]}>
          <Flex
            sx={{
              justifyContent: "center",
              flexDirection: "column",
              padding: [0, 5],
            }}
          >
            <Heading
              variant='h1'
              as='h1'
              sx={{ color: "#fff", textAlign: ["center", "left"] }}
            >
              {title}
            </Heading>
            <Paragraph
              sx={{
                color: "muted",
                textAlign: ["center", "left"],
                fontSize: 4,
                lineHeight: 1.2,
                maxWidth: 430,
                margin: "10px 0",
              }}
            >
              {description}
            </Paragraph>
            <List gap={"1px"}>
              <StructuredText data={list} />
            </List>
            <Flex sx={{ justifyContent: ["center", "flex-start"] }}>
              <Button
                as={NavLink}
                variant='link'
                href={link}
                target='_blank'
                sx={{ maxWidth: 200, marginBottom: [3, 0] }}
              >
                Get Started
              </Button>
            </Flex>
          </Flex>
          <Flex
            sx={{
              margin: 0,
              padding: 0,
              position: "relative",
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            <Card sx={{ position: "absolute", right: 0, top: [-5, 0] }}>
              <Heading
                sx={{
                  fontSize: [24, 46],
                  fontFamily: "heading",
                  color: "secondary",
                }}
              >
                {cardData?.heroCardAssessmentsTaken}+
              </Heading>
              <Text
                sx={{
                  color: "#74727d",
                  fontFamily: "heading",
                  fontSize: [2, 4],
                }}
              >
                assessments taken
              </Text>
              <Flex sx={{ paddingTop: 15, gap: 2, alignItems: "flex-end" }}>
                <Heading
                  variant='h3'
                  sx={{ color: "secondary", fontFamily: "heading" }}
                >
                  {cardData?.heroCardTraits}
                </Heading>
                <Text
                  sx={{
                    color: "#74727d",
                    fontFamily: "heading",
                    fontSize: [1, 3],
                  }}
                >
                  traits
                </Text>
              </Flex>
              <Flex sx={{ gap: 2, alignItems: "flex-end" }}>
                <Heading
                  variant='h3'
                  sx={{ color: "secondary", fontFamily: "heading" }}
                >
                  {cardData?.heroCardSkills}
                </Heading>
                <Text
                  sx={{
                    color: "#74727d",
                    fontFamily: "heading",
                    fontSize: [1, 3],
                  }}
                >
                  skills
                </Text>
              </Flex>
            </Card>
            <Image
              src={backdrop}
              sx={{ position: "absolute", bottom: 0, left: 0 }}
              alt=''
            />
            <LayoutGroup>
              <motion.div
                key={image[index]?.url}
                initial={{ opacity: 0, y: 0 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 0 }}
                style={{ width: isLargerThan551 ? 350 : 200, x: -25 }}
                transition={{
                  opacity: {
                    duration: 0.5,
                    type: "tween",
                    ease: "linear",
                  },
                }}
                layout
              >
                <DynamicImage
                  src={image[index]}
                  sx={{ objectFit: "contain", width: "100%" }}
                />
              </motion.div>
            </LayoutGroup>
          </Flex>
        </Grid>
      </Container>
    </Box>
  );
}
